import React from 'react';
import { Link } from 'gatsby';
import { Button, Box, Heading, Text } from 'theme-ui';

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`,
    // fontSize: 30,
  },
  occupation: {
    mb: 4,
  },
  specialty: {
    color: `text`,
    mb: 4,
  },
};

const HeroContent = () => (
  <>
    <Heading variant="h1">
      Hi, I'm <Text sx={styles.author}>Muhammad Ovi</Text>
    </Heading>
    <Heading variant="h1" sx={styles.occupation}>
      Software Engineer
    </Heading>
    <Heading variant="h3" sx={styles.specialty}>
      Specialized in Web &amp; App Development.
    </Heading>
    <Box variant="buttons.group">
      <Button as={Link} to="/contact">
        Contact Me
      </Button>
      <Button variant="white" as={Link} to="/author/muhammad-ovi">
        About Me
      </Button>
    </Box>
  </>
);

export default HeroContent;
