import React from 'react';
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout';
import CardList from '@components/CardList';
import Sticky from '@components/Sticky';
import Divider from '@components/Divider';
import Pagination from '@components/Pagination';
import Seo from '@widgets/Seo';
import Categories from '@widgets/Categories';
import Tags from '@widgets/Tags';
import NewsletterCompact from '@widgets/NewsletterCompact';
import Social from '@widgets/Social';
import HeroComponent from '../components/Hero/Hero';
import { useBlogTags, useBlogCategories } from '@helpers-blog';

const Posts = ({ data: { paginatedPosts = {} }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props;
  const tags = useBlogTags();
  const categories = useBlogCategories();

  return (
    <Layout {...props}>
      <Seo title="Home" />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent {...props} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <CardList
            variant={['horizontal-md', 'horizontal']}
            title="What I wrote recently"
            nodes={paginatedPosts.nodes}
            columns={[1]}
          />
        </Main>
        <Sidebar>
          <Categories categories={categories} />
          <Divider />
          <Tags tags={tags} />
          <Divider />
          <Social />
          <Sticky>
            {services.mailchimp && (
              <>
                <Divider />
                <NewsletterCompact />
              </>
            )}
          </Sticky>
        </Sidebar>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
      </PreFooter>
    </Layout>
  );
};

export default Posts;
